import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Progress.module.scss"

const cardData = [
  {
    heading: "I'm A",
    subHeading: "Startup.",
    description: "Remarkable ideas often stall without the right team to bring them to life",
    textColor: "black",
    button : "Get Started",
    link: "/software-development-services/startup/",
  },
  {
    heading: "I'm An",
    subHeading: "Enterprise.",
    description: "Scaling without the right infrastructure and expertise can lead to costly bottlenecks.",
    textColor: "white",
    button : "Explore More",
    link: "/software-development-services/enterprise/",

  },
  {
    heading: "I Need A",
    subHeading: "Rescue.",
    description: "A messy codebase and tech debt are suffocating your progress.",
    button : "Need a Fix",
    textColor: "black",
    link: "/software-development-services/rescue/",
  },
]

const Progress = () => {
  return (
    <div className={styles.progressSec}>
      <Container>
        <div className={styles.progress}>
          <h2 className={styles.heading}>
            Backing Your Up At <span>Every Stage Of Your Progress</span>
          </h2>
          <p className={styles.subDescription}>
            From launching startups to expanding enterprises, or recovering from
            setbacks, we help businesses overcome every challenge on their
            journey.
          </p>
          <Row className="gap-30">
            {cardData.map((item, index) => (
              <Col
                xl={4}
                lg={6}
                md={6}
                sm={12}
                key={index}
                className={`${styles.colGap} d-flex`}
              >
                <div className={`${styles.card} ${styles[`card${index + 1}`]}`}>
                  <p className={styles.heading}>{item.heading}</p>
                  <h3 className={styles.subHeading}>{item.subHeading}</h3>
                  <p
                    className={`${styles.description} ${
                      styles[item.textColor]
                    }`}
                  >
                    {item.description}
                  </p>
                  <Link to={item?.link} className={` ${styles[item.textColor]} ${styles.newBtn}`}>
                    {item?.button}
                  </Link>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <div className={styles.concerns}>
          <Row className="justify-content-between align-items-center">
            <h2>Got An Idea Or Concerns? Let’s Discuss.</h2>

            <div className={styles.cardFooter}>
            <Link to={"/contact-us/"} className="newBtn" style={{color: "white"}}>
              Explore More
            </Link>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Progress
