// extracted by mini-css-extract-plugin
export var backgroundVideo = "ModelsMobile-module--backgroundVideo--6a476";
export var btn = "ModelsMobile-module--btn--0e0df";
export var card = "ModelsMobile-module--card--cf95a";
export var cardWrapper = "ModelsMobile-module--cardWrapper--535e8";
export var dec = "ModelsMobile-module--dec--87f7e";
export var faqsNum = "ModelsMobile-module--faqsNum--0e95d";
export var faqsSub = "ModelsMobile-module--faqsSub--a5585";
export var gifImg = "ModelsMobile-module--gifImg--c0de8";
export var head = "ModelsMobile-module--head--302cc";
export var heading = "ModelsMobile-module--heading--4d9b4";
export var iconssCard = "ModelsMobile-module--iconssCard--bb5d5";
export var quesColor = "ModelsMobile-module--quesColor--80d58";
export var quesColorSelected = "ModelsMobile-module--quesColorSelected--dede3";
export var section = "ModelsMobile-module--section--90c09";
export var ser = "ModelsMobile-module--ser--02264";
export var serImg = "ModelsMobile-module--serImg--582c0";
export var serial = "ModelsMobile-module--serial--0c793";
export var serial2 = "ModelsMobile-module--serial2--b3d93";
export var talkBtn = "ModelsMobile-module--talkBtn--c0d3b";
export var techImg = "ModelsMobile-module--techImg--ab19d";
export var technologyIcon = "ModelsMobile-module--technologyIcon--e9d66";
export var textColor = "ModelsMobile-module--textColor--d925a";
export var yellow = "ModelsMobile-module--yellow--542fe";