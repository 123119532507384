// extracted by mini-css-extract-plugin
export var arrowPoint = "TechstackMobile-module--arrowPoint--a73ac";
export var btn = "TechstackMobile-module--btn--4b93f";
export var card = "TechstackMobile-module--card--c0ded";
export var cardWrapper = "TechstackMobile-module--cardWrapper--168d2";
export var dec = "TechstackMobile-module--dec--aa4e9";
export var faqsNum = "TechstackMobile-module--faqsNum--284f6";
export var faqsSub = "TechstackMobile-module--faqsSub--c91c0";
export var head = "TechstackMobile-module--head--27a1a";
export var headSearch = "TechstackMobile-module--headSearch--4df15";
export var heading = "TechstackMobile-module--heading--9206e";
export var iconssCard = "TechstackMobile-module--iconssCard--63458";
export var quesColor = "TechstackMobile-module--quesColor--573e7";
export var quesColorSelected = "TechstackMobile-module--quesColorSelected--562c5";
export var searchBar = "TechstackMobile-module--searchBar--fe7c2";
export var section = "TechstackMobile-module--section--3361c";
export var ser = "TechstackMobile-module--ser--9d93e";
export var serImg = "TechstackMobile-module--serImg--be69c";
export var serial = "TechstackMobile-module--serial--82c04";
export var serial2 = "TechstackMobile-module--serial2--c0737";
export var techImg = "TechstackMobile-module--techImg--7881a";
export var technologyIcon = "TechstackMobile-module--technologyIcon--27ef0";
export var textColor = "TechstackMobile-module--textColor--6abfc";
export var yellow = "TechstackMobile-module--yellow--f36c9";