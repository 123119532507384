// extracted by mini-css-extract-plugin
export var TechStacksWebHireAngular = "Techstack-module--TechStacksWebHireAngular--b8a0f";
export var button = "Techstack-module--button--e8c9a";
export var cardBg = "Techstack-module--cardBg--bdfe1";
export var cards = "Techstack-module--cards--b4c48";
export var cardsContentAngular = "Techstack-module--cardsContentAngular--6c298";
export var description = "Techstack-module--description--01743";
export var headSearch = "Techstack-module--headSearch--f450e";
export var heading = "Techstack-module--heading--2ec35";
export var iconssCard = "Techstack-module--iconssCard--9c28a";
export var inputSerch = "Techstack-module--inputSerch--a21d7";
export var nav = "Techstack-module--nav--92ec3";
export var navItem = "Techstack-module--nav-item--11162";
export var navbarBlock = "Techstack-module--navbarBlock--e59d8";
export var searchBar = "Techstack-module--searchBar--be4eb";
export var tabData = "Techstack-module--tabData--8d3a9";
export var tech = "Techstack-module--tech--eb30a";
export var techIcon = "Techstack-module--techIcon--06b4e";
export var technologyIcon = "Techstack-module--technologyIcon--ea6f1";