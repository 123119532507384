import React, { useContext } from "react"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { Link } from "gatsby"
import Card from "react-bootstrap/Card"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./ModelsMobile.module.scss"
import "./ModelsMobile.scss"

const data = [
  {
    title: "Product Development",
    dec: "Transforming your ideas into ready-to-launch products.",
    gifImage:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Infographic_02_a11ca74172.webm",
    tech: [
      {
        name: "Early Growth Stage or Stealth Startup.",
      },
      {
        name: "Product Development Roadmap",
      },
      {
        name: "MVP Development",
      },
      {
        name: "Prototypes / POC",
      },
    ],
  },
  {
    title: "Managed IT Systems",
    dec: "Streamlined IT management for smooth operations.",
    gifImage:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Infograpgic_03_2_4a215787c0.webm",
    tech: [
      {
        name: "Scale and ongoing or live products.",
      },
      {
        name: "Refining Product Roadmaps for scalability.",
      },
      {
        name: "Completing Backlogs or Features",
      },
      {
        name: "Defined Technology Stacks",
      },
    ],
  },
  {
    title: "Team Augmentation",
    dec: "Upgrade your team with specialized skills and expertise.",
    gifImage:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Infographic_1_adaa63c04a.webm",
    tech: [
      {
        name: "Operational team efficiency is high and nimble.",
      },
      {
        name: "Quick Turn-around times",
      },
      {
        name: "Access to the global talent pool",
      },
      {
        name: "Collaborate with in-house & Outsourced Teams",
      },
    ],
  },
]

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle
      as={Card.Header}
      onClick={decoratedOnClick}
      className={isCurrentEventKey ? styles.currentCard : ""}
    >
      <div>{children}</div>
    </Accordion.Toggle>
  )
}

const Technology2 = ({ strapiData }) => {
  return (
    <section className={styles.section}>
      <Container>
        <p className={styles.ser}>Models</p>
        <h2 className={styles.heading}>Engagement models</h2>
        <Row className="justify-content-center">
          <Col xl={7} lg={12} md={6}>
            <div className="modelsMobile">
              <Accordion>
                {data &&
                  data?.map((e, i) => (
                    <Card
                      key={i}
                      border="light"
                      style={{ overflow: "visible" }}
                    >
                      <ContextAwareToggle eventKey={`ek-${i}`}>
                        <div className={styles.faqsNum}>
                          <video
                            className={styles.backgroundVideo}
                            autoPlay
                            muted
                            loop
                          >
                            <source src={e?.gifImage} type="video/webm" />
                          </video>
                          <h3
                            className={`${styles.faqsSub} py-2 mb-0`}
                            type="button"
                          >
                            <h3>{e?.title}</h3>
                            <p className={styles.dec}>{e?.dec}</p>
                          </h3>
                        </div>
                      </ContextAwareToggle>
                      <Accordion.Collapse eventKey={`ek-${i}`}>
                        <Card.Body className={styles.textColor}>
                          <Row className={` ${styles.cardWrapper}`}>
                            {data[i]?.tech &&
                              data[i]?.tech?.map((el, i) => (
                                <Col lg={4} md={6} xs={12} key={i}>
                                  <div key={i}>
                                    <div
                                      className={styles.technologyIcon}
                                      key={i}
                                    >
                                      <div className={styles.techImg}>
                                        <img
                                          decoding="async"
                                          loading="lazy"
                                          src="https://invozone-backend.s3.us-east-1.amazonaws.com/check_3_7_8e20c0afd7.png"
                                          alt="check"
                                        />
                                      </div>
                                      <h3>{el?.name}</h3>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                            <div className={styles.talkBtn}>
                              <Link to="/contact-us/" className={styles.btn}>
                                View Details
                              </Link>
                            </div>
                          </Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
              </Accordion>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Technology2
