import React, { useRef } from "react"
import { Link } from "gatsby"
import * as styles from "./CaseStudies.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"

const data = [
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007654_b8e7b9380b.webp",
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/column_logo_396544fd95.png",
    title: "From Analog to Digital",
    head: "Turning Pen & Paper Processes into Agile SaaS Solutions.",
    button: "/portfolio/column/",
    button2: "Development",
  },
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007653_d770abba36.webp",
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/global_reader_logo_62f45929d7.png",
    title: "Real Time Factory Tracker",
    head: "Where Data Drives: Decisions, Efficiency, Downtime, Quality, Visibility, Competitivenes.",
    button: "/portfolio/globalreader/",
    button2: "AI-Powered Precision",
  },
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/freshprep_6300c3130d.webp",
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/freshprep_Logo_956ab76922.png",
    title: "Food Delivery & Retail",
    head: "Quick & effective resource augmentation for agile development",
    button: "/portfolio/freshprep/",
    button2: "Resource Augmentation",
  },
  {
    images:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/nymcard_f49e61a3a3.webp",
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/nymcard_logo_e0308594bb.png",
    title: "A Fintech Card Issuer & Service Provider",
    head: "Managed IT Teams For Modernizing Global Payment Platform",
    button: "/portfolio/nymcard/",
    button2: "Application Modernisation",
  },
]

const TrustInvoZone = ({ strapiData, projectEng }) => {
  const sliderRef = useRef(null)

  const settings = {
    infinite: false,
    autoplay: true,
    slidesToShow: 3,
    speed: 500,
    dots: false,
    dots: false,
    dotsClass: "clients-dots",
    arrows: false,
    responsive: [
      {
        breakpoint: 1270,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <div className={styles.headSearch}>
            <h2 className={styles.heading}>
              <span>Case Study</span>: We Don't Just Talk, We Deliver Results
            </h2>
            <div className={styles.cardFooter}>
              <Link to={"/portfolio/"} className="newBtn">
                View All
              </Link>
            </div>
          </div>
          <Row className="align-items-center">
            <Col lg={12} md={12} sm={12} xs={12}>
            </Col>
            <Slider
              {...settings}
              className={styles.SliderWidth}
              ref={sliderRef}
            >
              {data?.map((e, i) => (
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  key={i}
                  className={styles.rowGap}
                  onMouseEnter={() => sliderRef.current.slickPause()}
                  onMouseLeave={() => sliderRef.current.slickPlay()}
                >
                  <div className={styles.caseImages}>
                   
                    <img
                      src={e?.images}
                      alt="client logos"
                      decoding="async"
                      loading="lazy"
                      className={styles.clientImgs}
                    />
                    <div className={styles.blurrEffect}>
                      
                    </div>
             
                      <div className={styles.talkBtn2}>
                        <div className={styles.btn1}>
                          {e?.button2}
                        </div>
                      </div>
                      <div className={styles.content}>
                        <p>{e?.title}</p>
                        <h3>{e?.head}</h3>
                        <div className={styles.logos}>
                          <hr className={styles.line} />
                          <div className={styles.logoBtn}>
                            <img
                              src={e?.logos}
                              alt="client logos"
                              decoding="async"
                              loading="lazy"
                              className={styles.logoImages}
                            />
                          </div>
                        </div>
                      </div>

                      <Link to={e?.button} className={styles.btn2}>
                        <img
                          src="https://invozone-backend.s3.us-east-1.amazonaws.com/Vector_Stroke_123158fa91.png"
                          decoding="async"
                          loading="lazy"
                          className={styles.arrowImage}
                        />
                      </Link>
              
                  </div>
                </Col>
              ))}
            </Slider>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default TrustInvoZone
