// extracted by mini-css-extract-plugin
export var SliderWidth = "CaseStudies-module--SliderWidth--d3055";
export var Trust = "CaseStudies-module--Trust--2b85e";
export var arrowIcon = "CaseStudies-module--arrowIcon--8456b";
export var arrowImage = "CaseStudies-module--arrowImage--f7b8b";
export var arrowLink = "CaseStudies-module--arrowLink--36032";
export var blurrEffect = "CaseStudies-module--blurrEffect--1556e";
export var btn = "CaseStudies-module--btn--65500";
export var btn1 = "CaseStudies-module--btn1--d8cfb";
export var btn2 = "CaseStudies-module--btn2--dd102";
export var caseImages = "CaseStudies-module--caseImages--fe866";
export var clientImgs = "CaseStudies-module--clientImgs--2f4a5";
export var content = "CaseStudies-module--content--9c034";
export var headSearch = "CaseStudies-module--headSearch--7fb96";
export var heading = "CaseStudies-module--heading--20066";
export var logoImages = "CaseStudies-module--logoImages--38d51";
export var logos = "CaseStudies-module--logos--a32b7";
export var rowGap = "CaseStudies-module--rowGap--8d22f";
export var talkBtn = "CaseStudies-module--talkBtn--55616";
export var talkBtn2 = "CaseStudies-module--talkBtn2--94110";