import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import * as styles from "./ClientLogosMobile.module.scss"
import { Container } from "react-bootstrap"

const Team = () => {
  const data = [
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_1_391c160bf3.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_2_ad100c1f7e.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_1_3ed3284561.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_1_1_92c07828cf.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_5_efc36a42f4.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_2_1_458d4df155.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_5e8bdd18b3.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_7_3b1f295a77.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_8_90b80d2ede.png",
    },
    {
      logos:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Mask_group_9_7f35999064.png",
    },
  ]
  let settings = {
    autoplay: true,
    infinite: true,
    arrows: false,
    dots: false,
    // speed: 2000,
    slidesToShow: 6,
    // slidesToScroll: 1,
    // loop: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <div className={`${styles.wrap}`}>
      <Container>
        <p className={styles.grow}>
          <span> 400+ </span> products developed for <span> 300+ </span>
          customers across <span>120+ </span>countries.
        </p>
        <div className={styles.outer}>
          <Slider {...settings} className={styles.sliderblock}>
            {data.map((slide, index) => (
              <div key={index} className={styles.ContentArea}>
                <div>
                  <img
                    src={slide?.logos}
                    alt={`Slide ${index + 1}`}
                    id="decoding"
                    loading="lazy"
                    className="w-100 h-100"
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  )
}

export default Team
