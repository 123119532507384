// extracted by mini-css-extract-plugin
export var arrowAnimation = "Progress-module--arrowAnimation--17b69";
export var black = "Progress-module--black--9ac83";
export var card = "Progress-module--card--b74a9";
export var card1 = "Progress-module--card1--bc944";
export var card2 = "Progress-module--card2--ac582";
export var card3 = "Progress-module--card3--caea1";
export var colGap = "Progress-module--colGap--8e087";
export var concerns = "Progress-module--concerns--66cd5";
export var description = "Progress-module--description--7265c";
export var heading = "Progress-module--heading--0deb2";
export var newBtn = "Progress-module--newBtn--d0189";
export var progress = "Progress-module--progress--e5cae";
export var progressSec = "Progress-module--progressSec--24a83";
export var subDescription = "Progress-module--subDescription--e3984";
export var subHeading = "Progress-module--subHeading--c5bab";
export var talkBtn = "Progress-module--talkBtn--0c344";
export var white = "Progress-module--white--628f0";