// extracted by mini-css-extract-plugin
export var SliderWidth = "Clients-module--SliderWidth--413d8";
export var Trust = "Clients-module--Trust--5b318";
export var arrowPoint = "Clients-module--arrowPoint--910de";
export var blurInOut = "Clients-module--blurInOut--6da13";
export var buton = "Clients-module--buton--4f2f7";
export var cardContent = "Clients-module--cardContent--6c253";
export var cardFooter = "Clients-module--cardFooter--f661e";
export var cardHover = "Clients-module--cardHover--5b630";
export var cards = "Clients-module--cards--051aa";
export var greyCard = "Clients-module--greyCard--9296f";
export var heading = "Clients-module--heading--a304c";
export var iconContainer = "Clients-module--iconContainer--ffa31";
export var iconContainerLeft = "Clients-module--iconContainerLeft--b618e";
export var image2 = "Clients-module--image2--5e3eb";
export var indDec = "Clients-module--indDec--658be";
export var newBtn = "Clients-module--newBtn--34cad";
export var points = "Clients-module--points--75fad";
export var portfolioArrowIcon = "Clients-module--portfolioArrowIcon--e4f5f";
export var portfolioArrowIconCover = "Clients-module--portfolioArrowIconCover--10bda";
export var portfolioArrowRightIconCover = "Clients-module--portfolioArrowRightIconCover--4d0b8";
export var redCard = "Clients-module--redCard--ab29d";
export var sheildImgTop = "Clients-module--sheildImgTop--b1cfc";
export var shieldTopBannerImg = "Clients-module--shieldTopBannerImg--af454";
export var skill = "Clients-module--skill--7067f";
export var text = "Clients-module--text--b64b8";