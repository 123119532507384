// extracted by mini-css-extract-plugin
export var animateBg = "herosection2-module--animateBg--98d2f";
export var backgroundImage = "herosection2-module--backgroundImage--6a55e";
export var backgroundVideo = "herosection2-module--backgroundVideo--07373";
export var bannerSection = "herosection2-module--bannerSection--e06af";
export var borderImg = "herosection2-module--borderImg--5b8f6";
export var claedly = "herosection2-module--claedly--12416";
export var claedlyContainer = "herosection2-module--claedlyContainer--fe0ce";
export var claedlyDec = "herosection2-module--claedlyDec--2fc7d";
export var content = "herosection2-module--content--90802";
export var dec = "herosection2-module--dec--e9a87";
export var heading = "herosection2-module--heading--446f6";
export var imageBelowContent = "herosection2-module--imageBelowContent--514b7";
export var mainWrapper = "herosection2-module--mainWrapper--3c0db";
export var ripple1 = "herosection2-module--ripple1--14325";
export var ripple2 = "herosection2-module--ripple2--ff5e5";
export var ripple3 = "herosection2-module--ripple3--b6e34";
export var subHeading = "herosection2-module--subHeading--10b5c";
export var waveShadow = "herosection2-module--waveShadow--7652b";