// extracted by mini-css-extract-plugin
export var TechStacksWebHireAngular = "Models-module--TechStacksWebHireAngular--bb53d";
export var activeTab = "Models-module--activeTab--692a4";
export var btn = "Models-module--btn--41912";
export var cardBg = "Models-module--cardBg--ee70f";
export var cards = "Models-module--cards--410d7";
export var cardsContentAngular = "Models-module--cardsContentAngular--65902";
export var dec = "Models-module--dec--03cb9";
export var description = "Models-module--description--f293a";
export var engHeading = "Models-module--engHeading--fcdec";
export var heading = "Models-module--heading--ed7d7";
export var nav = "Models-module--nav--681b1";
export var navItem = "Models-module--nav-item--e0fe7";
export var navbarBlock = "Models-module--navbarBlock--0f807";
export var subHeading = "Models-module--subHeading--814e6";
export var tabData = "Models-module--tabData--dd587";
export var talkBtn = "Models-module--talkBtn--d8559";
export var tech = "Models-module--tech--841a6";
export var techImg = "Models-module--techImg--e4772";
export var technologyIcon = "Models-module--technologyIcon--08166";